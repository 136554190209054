/* ImageWithOverlay.css */
.image-container {
    position: relative;
    width: 100%;
    height: auto; /* Adjust as needed */
  }
  
  .background-image {
    width: 100%;
    height: 450px; /* Maintain aspect ratio */
  }
  
  .overlay-text {
    position: absolute;
    right: 20px; /* Adjust the distance from the right edge */
    top: 20px; /* Adjust the distance from the top edge */
    color: #fff; /* Text color */
    font-size: 24px; /* Adjust the font size */
    font-weight: bold; /* Adjust the font weight */
    background: rgba(0, 0, 0, 0); /* Optional: semi-transparent background */
    padding: 10px; /* Optional: padding around the text */
    border-radius: 5px; /* Optional: rounded corners */
    width:37%;
  }

  .shortDesc{
    color:var(--primaryFontColor);
    font-size: medium;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 300;
  }


  
  .bigFont{
      color:#EEEEEE
  }