.logo-section {
    padding: 40px 80px;
    width: 100%;
    position: relative;
}

.logo-container {
    width: 100%;
    overflow: hidden;
    position: relative;
    mask-image: linear-gradient(
        to right,
        transparent,
        black 15%,
        black 85%,
        transparent
    );
    -webkit-mask-image: linear-gradient(
        to right,
        transparent,
        black 15%,
        black 85%,
        transparent
    );
}

.logo-track {
    display: flex;
    animation: scroll 30s linear infinite;
    width: fit-content;
}

.logo-slide {
    flex: 0 0 auto;
    width: 200px;
    height: 80px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo-slide img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    opacity: 0.7;
    transition: all 0.3s ease;
    max-width: 180px;
}

.logo-slide img.logo-larger {
    max-width: 220px;
    transform: scale(1.2);
}

.logo-slide:hover img {
    opacity: 1;
    transform: scale(1.1);
}

.logo-slide:hover img.logo-larger {
    transform: scale(1.3);
}

@keyframes scroll {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-33.333%);
    }
}

/* Remove the previous fade effects if they exist */
.logo-container::before,
.logo-container::after {
    display: none;
}