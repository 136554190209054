.pageContainer {
  min-height: 100vh;
  padding: 40px 0;
  max-width: 1200px;
  margin: 0 auto;
  color: white;
}

.cardsContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  margin-top: 40px;
}

.card {
  background-color: #151b26;
  border-radius: 12px;
  padding: 24px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.infoContent {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.infoContent h2,
.formContent h2 {
  font-size: 1.5rem;
  margin-bottom: 16px;
  color: #fff;
}

.infoContent p {
  color: #8b95a1;
  line-height: 1.5;
  margin-bottom: 24px;
}

.contactInfo {
  margin: 2rem 0;
}

.contactItem {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
  padding: 8px 12px;
  background-color: #1c2331;
  border-radius: 4px;
  color: #8b95a1;
}

.illustration {
  max-width: 80%;
  margin: 2rem auto;
}

.socialLinks {
  margin-top: auto;
}

.socialLinks h3 {
  color: #fff;
  font-size: 1rem;
  margin-bottom: 12px;
}

.socialIcons {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.socialIcons a {
  color: #8b95a1;
  font-size: 1.5rem;
  transition: color 0.3s;
}

.socialIcons a:hover {
  color: #ff6b35;
}

.formContent {
  padding: 1rem;
}

.formGroup {
  margin-bottom: 1.5rem;
}

.formRow {
  display: flex;
  gap: 1rem;
}

.formGroup label {
  display: block;
  margin-bottom: 0.5rem;
  color: #fff;
  font-weight: 500;
}

.formGroup input,
.formGroup textarea {
  width: 100%;
  padding: 12px;
  border: none;
  border-radius: 4px;
  background-color: #1c2331;
  color: #fff;
  transition: all 0.3s;
}

.formGroup input::placeholder,
.formGroup textarea::placeholder {
  color: #8b95a1;
}

.formGroup input:focus,
.formGroup textarea:focus {
  outline: none;
  box-shadow: 0 0 0 2px #ff6b35;
}

.submitButton {
  width: 100%;
  padding: 12px 24px;
  background-color: #ff6b35;
  color: white;
  border: none;
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 1rem;
  transition: background-color 0.3s;
}

.submitButton:hover {
  background-color: #ff8c63;
}

@media (max-width: 768px) {
  .cardsContainer {
    grid-template-columns: 1fr;
  }
  
  .formRow {
    flex-direction: column;
  }
} 