.titleName{
    left:0px;
}
.logo{
    width:200x;
    height:80px;
}
.linkBtns{
    font-weight:700;
    text-decoration: none;
    padding-bottom: 6px;
    background-size: 100% 1px;
    background-position-x: 0%;
    background-position-y: calc(100% - 1px);
    background-repeat: no-repeat;
    padding-left: 0px;
    padding-right: 0px;
}
.linkBtns:hover{
    font-weight:700;
    background: linear-gradient(90deg, var(--highlightColor), var(--highlightColor));
    background-size: 100% 1px;
    background-position-x: 0%;
    background-position-y: calc(100% - 1px);
    background-repeat: no-repeat;
    animation-name: underlineHover;
    animation-duration: 0.6s;
    animation-timing-function: ease-in-out;
}

@keyframes underlineHover{
    0% {
        background-position-x: 100%;
        background-size: 100% 1px;
    }
    50% {
        background-position-x: 100%;
        background-size: 0% 1px;
    }
    51% {
        background-position-x: 0%;
        background-size: 0% 1px;
    }
    100% {
        background-position-x: 0%;
        background-size: 100% 1px;
    }
}