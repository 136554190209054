:root{
  --primaryColor:#1B262C;
  --secondaryColor:#OF4C75;
  --primaryFontColor:#BBE1FA;
  --secondaryFontColor:#3282B8;
  --highlightColor:#Ea553b;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*
primary background: #32012F
secondary background: #524C42
primary font color: #E2DFD0
highligh color: #F97300
*/

/*
primary background: #22831
secondary background: #393E46
primary font color: #00ADB5
highligh color: #EEEEEE
*/

/*
primary background: #1B262C
secondary background: #OF4C75
primary font color: #BBE1FA
highligh color: #3282B8
*/